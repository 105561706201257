<template lang="pug">
    v-container.grid-list-xl.login
        loader(v-if="$root.loader")
        template(v-else)
            v-snackbar(
                :timeout="3000",
                :top="true"
                :right="true"
                color="error"
                v-model="error")
                    | {{ errorText }}
                    v-btn(flat, dark, @click.native="error = false") {{$t('cerrar')}}
            .login-poster
                figure
                    img(:src="registerUrl" alt="Wappid")
            .login-content
                h2.secondary--text {{ $t('Restablecer contraseña')}}
                v-layout(row wrap)
                    v-flex(xs12)
                        v-text-field.medium(
                            v-model="form.password"
                            :label="$t('Nueva contraseña')"
                            required
                            :append-icon="e1 ? 'visibility' : 'visibility_off'"
                            :append-icon-cb="() => (e1 = !e1)"
                            :type="e1 ? 'password' : 'text'")
                    v-flex(xs12)
                        v-text-field.medium(
                            v-model="form.rpassword"
                            :label="$t('Confirmar contraseña')"
                            required
                            :append-icon="e2 ? 'visibility' : 'visibility_off'"
                            :append-icon-cb="() => (e2 = !e2)"
                            :type="e2 ? 'password' : 'text'")
                v-divider.mt-4
                .btns.btns-right
                    v-btn(color="secondary", outline, round, to="/login") {{$t('Iniciar sesión')}}
                    v-btn(@click.native="restore", color="primary", large, round) {{$t('Restablecer contraseña')}}
</template>

<script>

    import auth from 'mixins/auth'
    export default {
        mixins: [auth], 
        metaInfo (){
            return {
                title: this.$t('Restablecer contraseña')
            }
        },
        data() {
            return {
                e1: true,
                e2: true,
                form: {
                    password: '',
                    rpassword: ''
                },
                error: false,
                errorText: ''
            }
        },
        methods: {
            restore(){

                let form = Object.assign({}, this.form)
                form.t = this.$route.query.hasOwnProperty('t') ? this.$route.query.t : null

                this.$api(this, (xhr) => {
                    xhr.post('/restore', this.$qs.stringify(form)).then((r) => {

                        let data = r.data
                        
                        if(!data.response){
                            this.error = true
                            this.errorText = data.message
                        }else{
                            this.$router.push({ path: '/login' })
                        }
                    }).catch(() => {})
                })
            }
        }
    }

</script>
